/* Main elements */

html {
  overflow: hidden;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #383837;
  background-color: #f9f9f9;
}

h1,
h2,
h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
}

.h1,
.h2,
.h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
}

.h4,
.h5,
.h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

label {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

nav {
  font-family: "Raleway", sans-serif;
  font-weight: 900;
}

/* Button classes */
.btn-primary {
  background: #32355C;
  border-color: #32355C;
  color: white;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  min-width: 100px;
}

.btn-primary:hover {
  background: #5884f5;
  border-color: #5884f5;
  color: white;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #6d6e70 !important;
  border-color: #6d6e70 !important;
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #a6a8ab !important;
  border-color: #a6a8ab !important;
  opacity: 1;
  cursor: default;
}

.btn-secondary {
  background: #32355C;
  border-color: #32355C;
  color: white;
  font-weight: 700 !important;
  font-family: "Lato", sans-serif !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  width: 100px;
  font-size: 1em;
}

.btn-secondary:hover {
  background: #e6e7e8;
  border-color: #e6e7e8;
  color: #32355c;
}

.btn-secondary:active,
.btn-secondar.active {
  background: #e6e7e8 !important;
  border-color: #e6e7e8 !important;
  color: #32355c;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}
/* Cursor classes */
.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: #32355C;
}

.pointer.no-hover:hover {
  color: inherit;
}

.default-cursor {
  cursor: default;
}

/* background classes */

.bg-primary {
  background-color: #32355C !important;
}

.bg-white {
  background-color: #fafafa !important;
}

/* Font awesome styles */
.fa-info-circle:hover {
  color: #5884f5 !important;
}

/* .fas:hover {
  color: #5884f5 !important;
} */

/* Mapbox styles */
.popup {
  width: 250px !important;
  word-wrap: normal !important;
  text-align: left;
}

.mapboxgl-popup-content {
  background: #32355c !important;
  color: white !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-tip {
  border-top-color: #32355c !important;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-tip {
  border-bottom-color: #32355c !important;
}
.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
  border-right-color: #32355c !important;
}
.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
  border-left-color: #32355c !important;
}

.mapboxgl-popup-close-button {
  opacity: 0.8 !important;
  color: white !important;
  font-size: 1.6em !important;
  text-shadow: 1px 1px rgba(196, 196, 196, 0.671) !important;
}

.mapboxgl-popup-close-button:hover {
  opacity: 1 !important;
}

.mapboxgl-popup-close-button:active {
  opacity: 1 !important;
}

/* Logo */
.locatorx-logo {
  min-width: 115px;
  max-width: 275px;
}

/* create user */
.create-user .form-text.active {
  animation-name: slideup;
  animation-duration: 0.3s;
  font-size: 1.6rem;
  top: -1.2rem;
}

@keyframes slideup {
  from {
    font-size: 1.8rem;
    top: 0.7rem;
  }

  to {
    font-size: 1.6rem;
    top: -1.2rem;
  }
}

/* Opacity classes */

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.primary-text {
  color: #41434b;
}

.secondary-text {
  color: #6d6e70;
}

.light-text {
  color: #a6a8ab;
}

.hoverIcon {
  color: #32355C;
  cursor: pointer;
}

.hoverIconDisabled {
  cursor: not-allowed;
}

/* remove default button focus */
input:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

::-moz-focus-inner,
:active,
:focus {
  outline: none;
  border: 0;
  -moz-outline-style: none;
}

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); 
  clip: rect(1px,1px,1px,1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}